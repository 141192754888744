import * as ReactDOM from 'react-dom'
import './App.css';
import './index.css'
import SignUp from './components/signup'
import SignIn from './components/signin'
import { BrowserRouter, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <div className="wrapper">
      <BrowserRouter>
        <Switch>
          <Route path="/signin">
            <SignIn />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export { App }

ReactDOM.render(<App />, document.getElementById('root'))